.ql-container {
  overflow: auto;
  min-height: 100px;
  max-height: 200px;
}
.open .ql-container  {
    max-height: fit-content !important;
}
.ql-editor {
  min-height: 100px;
}
.ql-editor p,
.ql-editor ol,
.ql-editor ul,
.ql-editor pre,
.ql-editor blockquote,
.ql-editor h1,
.ql-editor h2,
.ql-editor h3,
.ql-editor h4,
.ql-editor h5,
.ql-editor h6 {
  margin: 0px 0 10px;
  font-size: initial;
  text-align: initial !important;
}
.ql-toolbar.ql-snow + .ql-container.ql-snow {
  border-top: 0px;
  border-radius: 0px 0px 10px 10px;
  border: 0;
  border-top: 1px solid #d2d2d6;
}
.ql-toolbar.ql-snow {
  border-radius: 10px 10px 0px 0px;
  z-index: 10001;
  border: 0;
}
.ql-container {
  overflow: auto;
  min-height: 100px;
}
.ql-toolbar.ql-snow .ql-formats {
  margin-right: 15px;
}
